import * as Sentry from "@sentry/browser";
import { Angular as AngularIntegration } from "@sentry/integrations";
import { captureMissingResources } from "./resources";
import { environment } from "./environment";
import { beforeSend } from "./errors";

window.sentry = environment.then((dsn) => {
  Sentry.init({
    dsn,
    integrations: [new AngularIntegration()],
    beforeSend,
    release:
      BUILD_APP_VERSION === ""
        ? `${BUILD_GIT_BRANCH}@${BUILD_TIMESTAMP}`
        : BUILD_APP_VERSION,
  });

  document.body.addEventListener("error", captureMissingResources, true);
});
