import * as sentry from "@sentry/browser";

const logMissingResource = (source) => {
  const message = `Failed to load resource: ${source}`;
  sentry.captureMessage(message, "warning");
  console.error(message);
};

export const captureMissingResources = ({ target }) => {
  if (target) {
    const tag = target.tagName.toLowerCase();

    if (["img", "script"].includes(tag)) {
      logMissingResource(target.src);
    }

    if (tag === "link") {
      logMissingResource(target.href);
    }
  }
};
